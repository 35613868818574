
body{
    background-color: #F3F4F9;
}


/* ////////////// FONT START /////////////////// */

.HomeFontRALEWAY {
    font-family: 'Raleway', sans-serif;
}

.HomeFontRALEWAYLIGHT {
    font-family: 'Raleway', sans-serif;
}


.FontFamilyMontSerret {
    font-family: 'Montserrat', sans-serif;
}

.YESEVA {
    font-family: 'Yeseva One', cursive;
}

/* /////  FONT END   ////// */

.mrpUNDERLINE{
    text-decoration: line-through;
}


.SILDERHEIGHT {
    height: 450px;
}

/* .ALLBG {
    height: 550px;
} */

@media only screen and (max-width: 1200px) {

    .FONTSIZE {
        font-size: 11px;
    }

    .SILDERHEIGHT {
        height: 400px;
    }

    /* .ALLBG {
        height: 400px;
    } */
  
}