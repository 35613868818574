/* ////////////// FONT START /////////////////// */

.HomeFontRALEWAY {
    font-family: 'Raleway', sans-serif;
}

.HomeFontRALEWAYLIGHT {
    font-family: 'Raleway', sans-serif;
}

.HomeFontEXCULSIVEPUNERATE {
    font-family: 'Shadows Into Light';
}

.FontFamilyMontSerret {
    font-family: 'Montserrat', sans-serif;
}

.YESEVA {
    font-family: 'Yeseva One', cursive;
}

/* /////  FONT END   ////// */



.BOOKINGHEADER {
    color: black;
    font-weight: 500;
}

.BOOKINGHEADER:hover {
    color: #633;
}


.nav-items {
    box-shadow: none;
}


.HEADERHOVER {
    font-size: 14px;
    padding: 6px;
    text-decoration: none;
    color: #fff;
    background-color: transparent;
    cursor: pointer;
}

/* .HEADERHOVER:hover {
    background-color: rgb(246, 185, 132);
    padding: 6px;
} */



.nav-i {
    font-size: 14px;
    text-decoration: none;
    color: #fff;
}

.nav-i:hover {
    text-decoration: underline;
}

.dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: none;
}


.dropdown-menu {
    top: 100%;
    background-color: #156380;
}

/* @media only screen and (max-width: 1200px) {

    

} */


@media screen and (min-width:993px) {

    
    .dropdown-menu {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
    }


    .nav-items:hover .dropdown-menu {
        display: block;
    }

    .nav-items {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}


.k12HEADER {
    font-size: 22px;
    color: #1B6A89;
    font-weight: 800;
}

.k12HEADER:hover {
    text-decoration: underline;
    color: #1B6A89;
    background-color: white;
}

.k12HIGHEREDUCATIONHEADER {
    font-size: 18px;
    color: #1B6A89;
    font-weight: 800;
}

.k12HIGHEREDUCATIONHEADER:hover {
    text-decoration: underline;
    color: #1B6A89;
    background-color: white;
}

.HEADERK12 {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.67;
}

.HEADERK12:hover {
    color: #633;
    background-color: white;
}

.HEADERK123 {
    color: #143d50;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.2;
}

.HEADERK123:hover {
    text-decoration: underline;
    color: #143d50;
    background-color: white;
}

.HEADERKHIGHEREDUCATION {
    color: #143d50;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.2;
}

.HEADERKHIGHEREDUCATION:hover {
    text-decoration: underline;
    color: #143d50;
    background-color: white;
}

.SOLHEADER {
    color: #143d50;
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.2;
}

.SOLHEADER:hover {
    text-decoration: underline;
    color: #143d50;
    background-color: #E0EAF0;
}

.SOLHEADER1 {
    color: #143d50;
    font-size: .975rem;
    font-weight: 400;
    line-height: 1.2;
}

.SOLHEADER1:hover {
    text-decoration: underline;
    color: #143d50;
    background-color: #E0EAF0;
}

.HEADERTRYCANVASBUTTON {
    font-size: 0.875rem;
    letter-spacing: 1px;
    color: #e4060f;
    background-color: unset;
    border: none;
    line-height: 1.2;
}

.HEADERTRYCANVASBUTTON:hover {
    color: #f76400;
}


.HEADERTRYCANVASBUTTONHEADERKHIGHEREDUCATION {
    font-size: 0.775rem;
    letter-spacing: 1px;
    color: #e4060f;
    background-color: unset;
    border: none;
    line-height: 1.2;
}

.HEADERTRYCANVASBUTTONHEADERKHIGHEREDUCATION:hover {
    color: #f76400;
}